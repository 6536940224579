import {
  Button,
  Drawer,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import UniverseForm from "./UniverseForm";
import ReplicateForm from "./ReplicateForm";
import { DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import Lottie from "react-lottie";
import VoyantisLaunchRocketAnimation from "../assets/voyantis-rocket-animation.json";
import { usePythonServerObject } from "../hooks/use-server-objects";
import { IUniverse } from "./IUniverse";
import dayjs from "dayjs";
import LoadingAnimation from "../assets/36923-vedhase.json";

const BASE_URI = process.env.REACT_APP_TBOARD_API_HOST;
const ENV = process.env.REACT_APP_ENV;

const baseCols = [
  {
    title: "Created At",
    key: "createdAt",
    render: (universe: IUniverse) => {
      return (
        <Typography.Text>
          {universe.createdAt} ({dayjs(universe.createdAt).fromNow()})
        </Typography.Text>
      );
    },
  },
  {
    title: "Owner",
    key: "name",
    render: ({ createdBy }: IUniverse) => {
      return (
        <Typography.Text
          ellipsis={{
            tooltip: `${createdBy}`,
          }}
        >
          {createdBy}
        </Typography.Text>
      );
    },
  },
  {
    title: "Universe DB Name",
    key: "dbName",
    render: ({ dbName }: IUniverse) => {
      return (
        <Typography.Text
          ellipsis={{
            tooltip: dbName,
          }}
        >
          {dbName}
        </Typography.Text>
      );
    },
  },
  {
    title: "Enabled",
    key: "enabled",
    render: ( { enabled } : IUniverse) => {
      var text = enabled ? "enabled" : "disabled";
      return(
      <Typography.Text type={enabled ? "success" : "danger"}>{text}</Typography.Text>
      )
    },
  },
];
const Universes = ({
  pride,
  token,
  username,
}: {
  pride: boolean;
  token: string;
  username: string;
}) => {
  const authHeader = { Authorization: `Bearer ${token}` };
  const headers = { ...authHeader };
  const fetchConfig = { headers };
  const [showUniverseForm, setShowUniverseForm] = useState(false);
  const [showReplicateForm, setShowReplicateForm] = useState(false);
  const [targetUniverseForReplicateDbName, setTargetUniverseForReplicateDbName] = useState<string | null>(null);  
  const [isMutatingUniverse, setIsMutatingUniverse] = useState(false);
  const [replicableUniverses, setReplicableUniverses] = useState<string[]>([]);

  const {
    serverObjects: universes,
    serverObjectsError: universesError,
    serverObjectsLoading: universesLoading,
    loadServerObjects: loadUniverses,
  } = usePythonServerObject<IUniverse>(
    () => "dr-strange/universes",
    fetchConfig
  );

  const handleFetchError = (e: Error) => {
    if (e.message === "Failed to fetch") {
      notification.warning({ message: "Timeout due to long process, wait a little bit more, and refresh the screen" });
    } else {
      notification.error({ message: e.message });
    }
  };

  const { run: createUniverse } = useRequest(
    async (universe: Omit<IUniverse, "_id">) => {
      setIsMutatingUniverse(true);

      const res = await fetch(`${BASE_URI}/dr-strange/universes`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify(universe),
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universe Created!" });
          loadUniverses().catch();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: refreshViews } = useRequest(
    async () => {
      setIsMutatingUniverse(true);
      const res = await fetch(`${BASE_URI}/dr-strange/views/refresh`, {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
        },
        body: JSON.stringify({"refresh" : true}),
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Views refreshed!" });
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: refreshUniverses } = useRequest(
    async () => {
      setIsMutatingUniverse(true);
      const res = await fetch(`${BASE_URI}/dr-strange/universes/refresh`, {
        method: "put",
        headers: {
          Accept: 
            "application/json",
            "Content-Type": "application/json",
            ...authHeader,
        },
        body: JSON.stringify({"user" : username}),
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universes refreshed!" });
          return;
        }
        notification.error({
          message: data.error,
          duration: 5,
        });
      },
    }
  );

  const { run: fetchReplicableUniverses } = useRequest(
    async () => {
      const res = await fetch(`${BASE_URI}/dr-strange/universes/replicable_universes`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
        },
      });
      const data = await res.json();
      return data;
    },
    {
      manual: true,
      onSuccess: (data: string[]) => {
        setReplicableUniverses(data);
      },
      onError: (e) => {
        handleFetchError(e);
      },
    }
  );

  const { run: replicateUniverse } = useRequest(
    async (universe: string, targetUniverseForReplicateDbName: string | null) => {
      setIsMutatingUniverse(true);

      const res = await fetch(`${BASE_URI}/dr-strange/universes/${targetUniverseForReplicateDbName}/replicate?source_db_name=${universe}`, {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          ...authHeader,
        },
      });
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universe Replicated!" });
          setShowReplicateForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  useEffect(() => {
    if (ENV === "staging") {
      fetchReplicableUniverses();
    }
  }, []);

  const { run: destroyUniverse } = useRequest(
    async (universe: IUniverse) => {
      setIsMutatingUniverse(true);

      const res = await fetch(
        `${BASE_URI}/dr-strange/universes/${universe.id}`,
        {
          method: "delete",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...authHeader,
          },
        }
      );
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: "Universe Destroyed!" });
          loadUniverses().catch();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );

  const { run: changeUniverseEnableState } = useRequest(
    async (universe: IUniverse) => {
      setIsMutatingUniverse(true);

      const command = universe.enabled ? "disabled" : "enabled";
      const res = await fetch(
        `${BASE_URI}/dr-strange/universes/${universe.id}/set_state`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...authHeader,
          },
          body: JSON.stringify({state: command})
        }
      );
      return res.json();
    },
    {
      manual: true,
      onError: (e) => {
        setIsMutatingUniverse(false);
        handleFetchError(e);
      },
      onSuccess: (data: any) => {
        setIsMutatingUniverse(false);
        if (data.success) {
          notification.success({ message: 'Changed universe enabled state' });
          loadUniverses();
          setShowUniverseForm(false);
          return;
        }
        const errorMessage = data.details.map(
          (detail: { stack: string }) => detail.stack
        );
        notification.error({
          message: data.error,
          duration: 0,
          description: (
            <div>
              <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                {data.message}
              </div>
              <ul>
                {errorMessage.map((m: string) => (
                  <li style={{ marginBottom: 5 }}>{m}</li>
                ))}
              </ul>
            </div>
          ),
        });
      },
    }
  );
  if (universesError) {
    return <div>Error loading universe list</div>;
  }
  return (
    <>
      <Drawer
        destroyOnClose={true}
        width={"40%"}
        open={showUniverseForm}
        onClose={() => {
          setShowUniverseForm(false);
        }}
      >
        <UniverseForm
          isMutating={isMutatingUniverse}
          onFinish={async (universe) => {
            await createUniverse({ ...universe, createdBy: username });
          }}
        />
      </Drawer>
      <Drawer
        destroyOnClose={true}
        width={"40%"}
        open={showReplicateForm}
        onClose={() => {
          setShowReplicateForm(false);
        }}
      >
        <ReplicateForm
          isMutating={isMutatingUniverse}
          universeOptions={replicableUniverses}
          onFinish={async (universe) => {
            await replicateUniverse(universe, targetUniverseForReplicateDbName);
          }}
        />
      </Drawer>
      <Drawer
        destroyOnClose={true}
        width={"40%"}
        open={isMutatingUniverse}
        placement="left" 
      >
        <Lottie
            style={{ margin: 0 }}
            options={{
              loop: true,
              autoplay: true,
              animationData: LoadingAnimation,
              rendererSettings: {},
            }}
            height={"100%"}
            width={"100%"}
            isStopped={false}
            isPaused={false}
          />

      </Drawer>
    
      {universesLoading ? (
        <div
          style={{
            width: "100%",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ margin: 0 }}
            options={{
              loop: true,
              autoplay: true,
              animationData: VoyantisLaunchRocketAnimation,
              rendererSettings: {},
            }}
            height={"10vw"}
            width={"10vw"}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) : (
        <Space direction={"vertical"} style={{ width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row"}}>
            <Button
              onClick={() => {
                setShowUniverseForm(true);
                setIsMutatingUniverse(false);
              }}
              type="primary"
            >
              New Universe
            </Button>
            <Button style={{ marginLeft: "10px" }}
              onClick={() => refreshViews()}
              type="primary"
            >
                Refresh joined db views
            </Button>
            <Popconfirm
              title={
                <Space direction={"vertical"}>
                  <div>Are you sure you want to refresh the universes? This is a resource-intensive operation!</div>
                  <div>Please ensure that no one else is running this process by checking the log messages:</div>
                  <div>"Start refreshing all universes" and "All universes refreshed"</div>
                  <div>(Note: You will not be able to perform this action if you are not authorized.)</div>
                </Space>
              }
              onConfirm={async () => {
                refreshUniverses();
              }}
            >
              <Button 
                  style={{ marginLeft: "10px" }}
                  type="primary"
                  loading={isMutatingUniverse}
                >
                  Refresh universes
              </Button>
            </Popconfirm>
          </div>
          <Table
            pagination={false}
            scroll={{ x: "100%" }}
            dataSource={universes}
            columns={[
              ...baseCols,

              {
                title: "Actions",
                key: "destroy",
                render: (universe) => (
                  <Space>
                    <Popconfirm
                      title={
                        <Space direction={"vertical"}>
                          <div>Universe will be destroyed!</div>
                        </Space>
                      }
                      onConfirm={async () => {
                        await destroyUniverse(universe);
                      }}
                    >
                      <Tooltip title="Destroy">
                        <Button
                          loading={isMutatingUniverse}
                          danger={true}
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                      title={
                        universe.enabled ? (
                          <Space direction={"vertical"}>
                            <div>Disable the universe?</div>
                          </Space>
                        ) : (
                          <Space direction={"vertical"}>
                            <div>Enable the universe?</div>
                          </Space>
                        )
                      }
                      onConfirm={async () => {
                        await changeUniverseEnableState(universe);
                      }}
                    >
                      <Tooltip title={universe.enabled ? "Disable Universe" : "Enable Universe"}>
                        <Button 
                          type={universe.enabled ? "default" : "primary"}
                          loading={isMutatingUniverse}
                        >
                          {universe.enabled ? "Disable" : "Enable"}
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                    {ENV === "staging" && (
                      <Button
                        style={{ backgroundColor: "green", color: "white" }}
                        loading={isMutatingUniverse}
                        onClick={() => {
                          setTargetUniverseForReplicateDbName(universe.dbName);
                          setShowReplicateForm(true);
                        }}
                      >
                        Replicate
                      </Button>
                    )}
                  </Space>
                ),
              },
            ]}
          />
        </Space>
      )}
    </>
  );
};

export default Universes;

import React, {useEffect, useState} from "react";
import {useRequest} from "ahooks";
import {
    Button, Popconfirm,
    Select,
    Space,
    notification,
} from "antd";
import SelectCustomerProduct from "./SelectCustomerProduct";
import SelectNormalizationVersion from "./SelectNormalizationVersion";

const formatTimestamp = (ts: number) => new Date(ts * 1000).toISOString();

function DeleteNormalization({
                                 token, userName,
                             }: {
    token: string;
    userName: string;
}) {
    const authHeader = {Authorization: `Bearer ${token}`};
    const headers = {
        ...authHeader,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const [customer, setCustomer] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [ingestionStartTime, setIngestionStartTime] = useState<number | null>(null)
    const [version, setVersion] = useState<number | undefined>();

    const {
        data: ingestionTimes,
        loading: ingestionTimesLoading,
        run: loadIngestionTimes
    } = useRequest<number[], any>(async ({
                                             customer,
                                             product,
                                             version
                                         }: { customer: string, product: string, version: number }) => {
        const res = await fetch(
            `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/normalization/${version}/normalization-times`,
            {headers}
        );
        return res.json();
    }, {manual: true})

    const {
        run: deleteNormalization
    } = useRequest<number[], any>(async () => {
        const res = await fetch(
            `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/normalization/${version}/delete`,
            {
                headers,
                method: "POST",
                body: JSON.stringify({
                    "ingestion_time_start": ingestionStartTime,
                    "user": userName,
                })
            }
        );
        return res.json();
    }, {
        manual: true, onSuccess: () => {
            notification.success({message: "Normalization deletion started!"});
            setIngestionStartTime(null);
        },
        onError: (e) => {
            console.log(e);
            notification.error({message: `Error running deletion ${e.message}`})
        }
    })

    useEffect(() => {
        if (customer && product && version) {
            loadIngestionTimes({
                customer: customer,
                product: product,
                version: version
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, product, version])

    return (
        <Space direction={"vertical"}>
            <div>
                <h3>Choose Product:</h3>
                <SelectCustomerProduct onChange={v => {
                    setIngestionStartTime(null);
                    setProduct(v?.product);
                    setCustomer(v?.customer);
                }}/>
            </div>
            {customer && product &&
            <div>
                <h3>Choose Version:</h3>
                <SelectNormalizationVersion
                    customer={customer}
                    product={product}
                    onChange={(v) => {
                        setIngestionStartTime(null);
                        setVersion(v)
                    }}/>
            </div>
            }
            <div>
                <h3>Delete up to:</h3>
                <Select
                    disabled={!Boolean(product)}
                    loading={ingestionTimesLoading}
                    value={ingestionStartTime ?? undefined}
                    style={{width: 500}}
                    placeholder={ingestionTimesLoading ? "Loading options..." : "Choose Ingestion Time"}
                    optionFilterProp="children"
                    onChange={setIngestionStartTime}
                >
                    <Select.Option value={0}>Dawn of time 🧙🏿</Select.Option>
                    {ingestionTimes?.map(ts => <Select.Option
                        key={ts}
                        value={ts}>
                        {formatTimestamp(ts)}
                    </Select.Option>)}
                </Select>
            </div>
            <div>
                <Popconfirm
                    title={<Space direction={"vertical"}>
                        {ingestionStartTime === 0 ? <div>
                            All normalized data will be deleted!
                        </div> : <>
                            <div>
                                We are going to delete all normalized data up to
                                (incl) {formatTimestamp(ingestionStartTime!)}
                            </div>
                            <div>
                                and move the normalizer state back to {formatTimestamp(ingestionStartTime!)}</div>
                        </>}

                    </Space>}
                    onConfirm={async () => {
                        await deleteNormalization()
                    }}>
                    <Button disabled={Boolean(ingestionStartTime === null)} type={"primary"}
                            size="large">Delete</Button>
                </Popconfirm>
            </div>
        </Space>
    );
}

export default DeleteNormalization;

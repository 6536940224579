import React, {useState} from "react";
import {
    Button,
    Checkbox,
    Form, notification, Select,
} from "antd";
import {useGetGrindorSlugs, useManualTrigger, useGetGrindorWarehouseGroups, GrindorTriggerBody} from "../../../api/grindor";




interface GrindorTriggerParams {
    customer: string;
    product: string;
    version: number;
    userName: string;
}


export const GrindorTrigger: React.FC<GrindorTriggerParams> = (
    {
        customer,
        product,
        version,
        userName
    }) => {
    const [slugs, setSlugs] = useState<string[]>([])
    const [fullRefresh, setFullRefresh] = useState(false)
    const [triggerDependentJobs, setTriggerDependentJobs] = useState(false)
    const [warehouse_group, setWarehouseGroup] = useState<string | null>(null)
    const {
        data: slugsOptions,
        loading: slugsOptionsLoading,
    } = useGetGrindorSlugs(customer, product, version);
    const {
		data: warehouseGroups,
		loading: warehouseGroupsLoading,
	} = useGetGrindorWarehouseGroups();

    const {
        loading: loadingManualTrigger,
        runAsync: manualTrigger
    } = useManualTrigger(customer, product, version, {
        onSuccess: () => {
            notification.success({message: 'Grindor triggered'});
        },
        onError: (error) => {
            notification.error({
                message: 'Failed triggering grindor',
                description: error.toString()
            });
        }
    })

    return <div>
        <Form.Item label={"Slugs"}>
            <Select
                loading={slugsOptionsLoading}
                showSearch
                allowClear={true}
                style={{width: 500}}
                placeholder="slug"
                mode="multiple"
                optionFilterProp="children"
                onChange={(slugs: string[]) => {
                    setSlugs(slugs);
                }}
                onClear={() => {
                    setSlugs([]);
                }}
            >
                {slugsOptions?.slugs.sort()
                    .map((slug: string) => (
                        <Select.Option
                            key={slug}
                            value={slug}
                        >
                            {slug}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
        <Form.Item label={"Full Refresh"}>
            <Checkbox
                checked={fullRefresh}
                onChange={(e) => {
                    setFullRefresh(e.target.checked)
                }}
            />
        </Form.Item>
        <Form.Item label={"Trigger Dependent Jobs"}>
            <Checkbox
                checked={triggerDependentJobs}
                onChange={(e) => {
                    setTriggerDependentJobs(e.target.checked)
                }}
            />
        </Form.Item>
	    <Form.Item label={"Warehouse Group"}>
		    <Select
			    loading={warehouseGroupsLoading}
			    value={warehouse_group}
			    allowClear={true}
			    placeholder="warehouse group"
			    onChange={(warehouse_group: string) => {
				    setWarehouseGroup(warehouse_group)
			    }}
			    onClear={() => {
				    setWarehouseGroup(null);
			    }}
		    >
			    {warehouseGroups?.warehouse_groups?.map((whg: string) => (
				    <Select.Option
					    key={whg}
					    value={whg}
				    >
					    {whg}
				    </Select.Option>
			    ))}
		    </Select>
	    </Form.Item>
	    <Button disabled={slugs?.length === 0 || loadingManualTrigger} type={"primary"}
		    onClick={() => {
			const triggerParams: GrindorTriggerBody = {
			    slugs: slugs,
			    full_refresh: fullRefresh,
			    warehouse_group: warehouse_group,
			    force_trigger: true,
                user: userName,
			    ignore_lineage: !triggerDependentJobs,
			};
			manualTrigger(triggerParams);
		    }}>
		Trigger
	    </Button>
    </div>
}

import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useGetGrindorSlugProducts} from "../../api/grindor";

interface SelectSupportedCustomersProps {
    slug: string;
    onChange: (value: string[] | undefined) => void;
}


const SelectSupportedCustomers = (props: SelectSupportedCustomersProps) => {
    const {slug, onChange} = props;
    const [product, setProduct] = useState<string[] | undefined>()
    const {
        data: products,
        error:productsError,
        loading: productsLoading,
    } = useGetGrindorSlugProducts(slug);

    useEffect(() => {
        setProduct(undefined);
    }, [productsLoading])

    useEffect(() => {
        onChange(product)
        // TODO: fix with useCallback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product])

    const handleSelectChange = (value: string[]) => {
        if (value.includes("select_all")) {
          setProduct(products?.slugs);
        } else {
          setProduct(value);
        }
      };

    if (productsError) {
        return <div>Error loading slug's supported customers</div>;
    }
    return (
        <Select
            value={product}
            mode="multiple"
            disabled={productsLoading}
            loading={productsLoading}
            showSearch
            allowClear={true}
            style={{width: 500}}
            placeholder="customer-product-version"
            onChange={handleSelectChange}
            onClear={() => setProduct(undefined)}
        >
            <Select.Option key="select_all" value="select_all">
                Select All
            </Select.Option>
            {!productsLoading && products?.slugs?.map((v) => (
                <Select.Option
                    key={v}
                    value={v}
                >
                    {v}
                </Select.Option>
            ))}
        </Select>
    );
};
export default SelectSupportedCustomers;

import React from "react";
import {Descriptions, Space} from "antd";
import dayjs from "dayjs";
import "./IngestionStatus.css";
import {NormalizerState} from "../IngestionStatus";
import Status from "./Status";

const NormalizerStateView = ({
                                 normalizerState,
                                 normalizationVersion,
                                 product,
                                 customer,
                             }: {
    normalizerState: NormalizerState;
    normalizationVersion: number,
    product: string;
    customer: string;
}) => {
    return (
        <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label="Data Normalized Time">
                {dayjs(normalizerState.last_ingested_time).format(
                    "YYYY-MM-DD HH:mm:ss [UTC]"
                )}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
                <Status
                    enabled={true}
                    isRunning={normalizerState.is_running}
                    errorLevel={normalizerState.error_level}
                    errorMessage={normalizerState.error_message}
                />
            </Descriptions.Item>
            <Descriptions.Item label="Logs"><Space>
                <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`https://console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/${normalizerState.aws_batch_job_id}`}
                >
                    aws
                </a>
                <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`https://prod.kibana.voyantis.io/_plugin/kibana/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15d,to:now))&_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.product,negate:!f,params:(query:${product}),type:phrase),query:(match_phrase:(context.product:${product}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.customer,negate:!f,params:(query:${customer}),type:phrase),query:(match_phrase:(context.customer:${customer}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.app_name,negate:!f,params:(query:nj-normalizer),type:phrase),query:(match_phrase:(context.app_name:nj-normalizer))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.version,negate:!f,params:(query:'${normalizationVersion}'),type:phrase),query:(match_phrase:(context.version:'${normalizationVersion}')))),index:'75449b20-9125-11eb-9da6-f373fd5db261',interval:auto,query:(language:kuery,query:''),sort:!())`}
                >
                    kibana
                </a>
            </Space>
            </Descriptions.Item>
        </Descriptions>
    );
};

export default NormalizerStateView;

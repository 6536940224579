import React from "react";
import {Table, Tag, Tooltip, Space} from "antd";
import dayjs from "dayjs";
import "./IngestionStatus.css";
import Status from "./Status";
import {CustomerIngestionState, PipelineState} from "../IngestionStatus";
import {SyncOutlined} from "@ant-design/icons";

interface DataRecord extends PipelineState {
    key: string;
    colorCode: number;
    isSyncedPipeline: boolean;
}

const columns = [
    {
        title: "Name",
        key: "pipeline",
        width: 300,
        render: ({isSyncedPipeline, key}: DataRecord) => {
            return (
                <div>
                    {key}{" "}
                    {isSyncedPipeline ? (
                        <Tooltip title={`Synchronizer awaits ${key} pipeline`}>
                            <SyncOutlined/>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </div>
            );
        },
    },
    {
        title: "Data Ingested Time",
        dataIndex: "last_ingested_time",
        key: "last_ingested_time",
        render: (lastIngestedTime: string) => {
            if (!lastIngestedTime) {
                return ""
            }

            return (
                <Tag>
                    {dayjs(lastIngestedTime).format("YYYY-MM-DD HH:mm:ss [UTC]")}
                </Tag>
            );
        },
    },
    {
        title: "Synchronizer Time",
        dataIndex: "synchronizer_ingestion_time",
        key: "synchronizer_ingestion_time",
        render: (
            synchonrizerIngestionTime: string,
            {last_ingested_time: lastIngestedTime}: DataRecord
        ) => {
            if (!synchonrizerIngestionTime) {
                return ""
            }
            const formattedSynchonrizerIngestionTime = dayjs(
                synchonrizerIngestionTime
            ).format("YYYY-MM-DD HH:mm:ss [UTC]");
            const formattedLastIngestedTime = dayjs(lastIngestedTime).format(
                "YYYY-MM-DD HH:mm:ss [UTC]"
            );
            return (
                <Tag>
                    {formattedSynchonrizerIngestionTime}{" "}
                    {formattedLastIngestedTime !== formattedSynchonrizerIngestionTime &&
                        " ❌"}
                </Tag>
            );
        },
    },
    {
        title: "Status",
        render: ({
                     is_running: isRunning,
                     error_level: errorLevel,
                     error_message: errorMessage,
                     config,
                     pipeline,
                     id
                 }: DataRecord) => {
            if (id === "fb_ads" || id === "google_ads" || id === "biopsy") {
                return ""
            }
            return (

                <Status
                    enabled={config.enabled}
                    name={pipeline}
                    isRunning={isRunning}
                    errorLevel={errorLevel}
                    errorMessage={errorMessage}
                />
            );
        },
    },
    {
        title: "Logs",
        key: "logs",
        render: (
            _text: string,
            {
                customer,
                product,
                pipeline: name,
                aws_batch_job_id: awsBatchJobId,
                id
            }: DataRecord
        ) => {
            if (id === "fb_ads" || id === "google_ads" || id === "biopsy") {
                return ""
            }
            return (
                <Space>
                    <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`https://console.aws.amazon.com/batch/home?region=us-east-1#jobs/detail/${awsBatchJobId}`}
                    >
                        aws
                    </a>
                    <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`https://prod.kibana.voyantis.io/_plugin/kibana/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15d,to:now))&_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.pipeline,negate:!f,params:(query:${name}),type:phrase),query:(match_phrase:(context.pipeline:${name}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.product,negate:!f,params:(query:${product}),type:phrase),query:(match_phrase:(context.product:${product}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'75449b20-9125-11eb-9da6-f373fd5db261',key:context.customer,negate:!f,params:(query:${customer}),type:phrase),query:(match_phrase:(context.customer:${customer})))),index:'75449b20-9125-11eb-9da6-f373fd5db261',interval:auto,query:(language:kuery,query:''),sort:!())`}
                    >
                        kibana
                    </a>
                </Space>
            );
        },
    },
];

const PipelineStatusTable = ({
                                 customerIngestionState,
                             }: {
    customerIngestionState: CustomerIngestionState;
}) => {
    const {synchronizer} = customerIngestionState;
    const ingestionTimes = Array.from(
        new Set(
            customerIngestionState.pipelines.map(
                (pipeline) => pipeline.last_ingested_time
            )
        )
    )
        .sort()
        .reverse();
    const isSyncedPipeline = (pipeline: string) =>
        customerIngestionState.synchronizer.config.enabled_pipelines.includes(
            pipeline
        );
    const data = customerIngestionState.pipelines.map((pipeline) => {
        return {
            ...pipeline,
            synchronizer_ingestion_time: synchronizer?.pipelines_ingestion_time?.[pipeline.pipeline],
            key: pipeline.pipeline,
            colorCode: ingestionTimes.indexOf(pipeline.last_ingested_time),
            isSyncedPipeline: isSyncedPipeline(pipeline.pipeline),
        };
    });
    const internalPipelines = data.filter(d => !(d.pipeline.startsWith("fb_ads") || d.pipeline.startsWith("google_ads") || d.config.is_biopsy))
    const fbAdsPipelines = data.filter(d => d.pipeline.startsWith("fb_ads"))
    const googleAdsPipelines = data.filter(d => d.pipeline.startsWith("google_ads"))
    const biopsyPipelines = data.filter(d => d.config.is_biopsy)

    const PipelineTable = ({pipelines}: { pipelines: DataRecord[] }) => <Table
        columns={columns}
        expandable={{
            expandedRowRender: ({id}) => {
                if(id==="fb_ads"){
                    return PipelineTable({pipelines: fbAdsPipelines})
                }
                if(id==="google_ads"){
                    return PipelineTable({pipelines: googleAdsPipelines})
                }
                if(id==="biopsy"){
                    return PipelineTable({pipelines: biopsyPipelines})
                }
                throw new Error(`what table to show for ${id}`)
            }, rowExpandable: ({id}) => {
                return id === "fb_ads" || id === "google_ads" || id === "biopsy"
            }
        }}
        dataSource={pipelines.sort((a, b) => {
            if (b.id === "fb_ads" || b.id === "google_ads" || b.id === "biopsy") {
                return -1;
            }
            if (isSyncedPipeline(a.pipeline) && isSyncedPipeline(b.pipeline)) {
                return a.pipeline > b.pipeline ? 1 : -1;
            }

            if (!isSyncedPipeline(a.pipeline) && !isSyncedPipeline(b.pipeline)) {
                return a.pipeline > b.pipeline ? 1 : -1;
            }

            return isSyncedPipeline(a.pipeline) ? -1 : 1;
        })}
        pagination={false}
    />
    const mainTablePipelines = [...internalPipelines];
    if (fbAdsPipelines.length > 0) {
        mainTablePipelines.push({
            pipeline: "Facebook Ads",
            isSyncedPipeline: false,
            key: "Facebook Ads",
            last_ingested_time: "",
            synchronizer_ingestion_time: "",
            colorCode: 0,
            product: "",
            customer: "",
            id: "fb_ads",
            is_running: false,
            config: {enabled: true, trigger_range: "", trigger_condition: "", schedule_cron: "", is_biopsy: false}
        })
    }
    if (googleAdsPipelines.length > 0) {
        mainTablePipelines.push({
            pipeline: "Google Ads",
            isSyncedPipeline: false,
            key: "Google Ads",
            last_ingested_time: "",
            synchronizer_ingestion_time: "",
            colorCode: 0,
            product: "",
            customer: "",
            id: "google_ads",
            is_running: false,
            config: {enabled: true, trigger_range: "", trigger_condition: "", schedule_cron: "", is_biopsy: false}
        })
    }
    if (biopsyPipelines.length > 0) {
        mainTablePipelines.push({
            pipeline: "Biopsy",
            isSyncedPipeline: false,
            key: "Biopsy",
            last_ingested_time: "",
            synchronizer_ingestion_time: "",
            colorCode: 0,
            product: "",
            customer: "",
            id: "biopsy",
            is_running: false,
            config: {enabled: true, trigger_range: "", trigger_condition: "", schedule_cron: "", is_biopsy: false}
        })
    }
    return <PipelineTable
        pipelines={mainTablePipelines}/>;
};
export default PipelineStatusTable;

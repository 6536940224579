import React, {useState} from "react";
import {
    Divider,
    Space,
    Switch
} from "antd";
import SelectCustomerProduct from "./SelectCustomerProduct";
import SelectGrindorVersion from "./SelectGrindorVersion";
import SelectSlug from "./SelectSlug";
import SelectSupportedCustomers from "./SelectSlugSupportedCustomers";
import {Content} from "antd/es/layout/layout";
import {Grindor, GrindorMultipleCustomers} from "./Grindor/Grindor";


function Control({userName}: { 
    userName: string;
}) {
    const [customer, setCustomer] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [version, setVersion] = useState<number>();
    const [slug, setSlug] = useState<string>();
    const [isBySlug, setIsBySlug] = useState<boolean>(false);
    const [bySlugProducts, setBySlugProducts] = useState<string[]>();
    return <>
        <Space direction={"horizontal"}>
            <div>
                <Switch
                    unCheckedChildren="Operate on single product" checkedChildren="Operate on multiple products"
                    checked={Boolean(isBySlug)}
                    onChange={() => setIsBySlug((p: any) => (p ? false : true))}
                  />
                {!isBySlug && <div><h3>Customer & Product:</h3>
                <SelectCustomerProduct onChange={v => {
                    if (!v) {
                        setProduct(undefined);
                        setCustomer(undefined);
                        return;
                    }
                    setProduct(v.product);
                    setCustomer(v.customer);
                }}/>
                </div>}
            {customer && product && !isBySlug &&
            <div>
                <h3>Version:</h3>
                <SelectGrindorVersion
                    customer={customer}
                    product={product}
                    onChange={setVersion}/>
            </div>
            }
            {isBySlug &&
            <div>
                <h3>Slug:</h3>
                <SelectSlug
                    onChange={setSlug}/>
            {slug != undefined &&
            <div>
                <h3>Supported products:</h3>
                <SelectSupportedCustomers
                    slug={slug}
                    onChange={setBySlugProducts}/>
                {bySlugProducts &&
                <div>
                <GrindorMultipleCustomers slug={slug} products={bySlugProducts} userName={userName}/>
                </div>
                }
            </div>
            }
            </div>
            }
            </div>

        </Space>
        <Divider/>
        <Content>
            {customer && product && version != null &&
            <Grindor customer={customer} product={product} version={version} userName={userName}/>
            }
        </Content>
    </>
        ;
}

export default Control;

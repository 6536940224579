import React from "react";
import {Layout, Tabs} from "antd";
import {useLocalStorageState} from "ahooks";
import {Switch, Space} from "antd";

import {useTitle} from "ahooks";
import Lottie from "react-lottie";
import VoyantisLogoAnimation from "../assets/voyantis-logo-antimation.json";
import {Route, useHistory, useParams} from "react-router-dom";
import DeleteNormalization from "./BumblebeeApp/DeleteNormalization";
import ManualNormalization from "./BumblebeeApp/ManualNormalization";
import IngestionStatus from "./BumblebeeApp/IngestionStatus";
import ManualTriggerPipeline from "./BumblebeeApp/ManualTriggerPipeline";
import DeletePipelineData from "./BumblebeeApp/DeletePipelineData";
import UtcTime from "../UtcTime";
import useWindowSize from "../hooks/use-window-size";
import Control from "./BumblebeeApp/Control";

const {Header, Content} = Layout;

function BumblebeeTabs({
                           pride,
                           token,
                           userName,
                       }: {
    pride: boolean;
    token: string;
    userName: string;
}) {
    const history = useHistory();
    const {tab} = useParams<{ tab?: string }>();
    return (
        <Tabs
            defaultActiveKey={tab}
            onChange={(key) => {
                history.push(`/bumblebee/${key}`);
            }}
        >
            <Tabs.TabPane tab="Ingestion Status" key="status">
                <IngestionStatus token={token}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Manual Normalization" key="norm-manual">
                <ManualNormalization token={token} userName={userName}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Delete Normalization" key="norm-delete">
                <DeleteNormalization token={token} userName={userName}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Manual Pipeline Trigger" key="pipeline-manual">
                <ManualTriggerPipeline token={token} userName={userName}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Delete Pipeline Data" key="pipeline-delete">
                <DeletePipelineData token={token} userName={userName}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Grindor" key="control">
                <Control userName={userName}/>
            </Tabs.TabPane>
        </Tabs>
    );
}

function BumblebeeApp({
                          environment,
                          token,
                          userName,
                      }: {
    environment: string;
    token: string;
    userName: string;
}) {
    const [pride, setPride] = useLocalStorageState("isPrideMode");
    const {width} = useWindowSize();
    useTitle("Bumblebee");
    return (
        <>
            <Layout className={pride ? "pride" : ""}>
                <Header
                    className={"header"}
                    style={{
                        position: "fixed",
                        zIndex: 1,
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "white",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "5px solid #003246",
                    }}
                >
                    <Space>
                        <Lottie
                            style={{margin: 0}}
                            options={{
                                loop: false,
                                autoplay: true,
                                animationData: VoyantisLogoAnimation,
                                rendererSettings: {},
                            }}
                            height={65}
                            width={100}
                            isStopped={false}
                            isPaused={false}
                        />
                        <h2 style={{margin: 0}}>Bumblebee</h2>
                        <h2 style={{margin: 0, color: "red"}}>{environment}</h2>
                    </Space>
                    {width && width > 600 &&
                    <>
                        <div>
                            <UtcTime/>
                        </div>
                        <div
                            style={{
                                alignSelf: "flex-end",
                                display: "flex",
                                width: 200,
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div>
                                <Space>
                                    {userName}
                                    <Space>
                                        🌈
                                        <Switch
                                            checked={Boolean(pride)}
                                            onChange={() => setPride((p: any) => (p ? "" : "true"))}
                                        />
                                    </Space>
                                </Space>
                            </div>
                        </div>
                    </>
                    }
                </Header>
                <Content
                    style={{minHeight: "100vh", padding: "10px 5%", marginTop: "64px"}}
                >
                    <Route path="/bumblebee/:tab?">
                        <BumblebeeTabs
                            pride={Boolean(pride)}
                            token={token}
                            userName={userName}
                        />
                    </Route>
                </Content>
            </Layout>
        </>
    );
}

export default BumblebeeApp;

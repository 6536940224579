import { Button, Form, Select, Popconfirm } from "antd";
import React, { useState } from "react";

const { Option } = Select;

const ReplicateForm: React.FC<{
  onFinish: (selectedUniverse: string) => void;
  isMutating: boolean;
  universeOptions: string[];
}> = ({ onFinish, isMutating, universeOptions }) => {
  const [form] = Form.useForm();
  const [isUniverseSelected, setIsUniverseSelected] = useState(false);

  return (
    <Form
      onFinish={async (formValue) => {
        onFinish(formValue.universe);
      }}
      form={form}
      onValuesChange={(changedValues) => {
        setIsUniverseSelected(!!changedValues.universe);
      }}
    >
      <Form.Item
        label="Source Universe"
        name="universe"
        rules={[{ required: true }]}
        help="Select a source universe where you want to copy from"
      >
        <Select 
          showSearch
          placeholder="Select a universe"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {universeOptions.map((universe) => (
            <Option key={universe} value={universe}>
              {universe}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Popconfirm
          title="Are you sure you want to replicate this universe?"
          onConfirm={() => form.submit()}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type={"primary"}
            loading={isMutating}
            disabled={!isUniverseSelected}
          >
            {"Replicate"}
          </Button>
        </Popconfirm>
      </Form.Item>
    </Form>
  );
};

export default ReplicateForm;

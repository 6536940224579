import React, {useState} from "react";
import {useRequest} from "ahooks";
import {
    Button, notification, Popconfirm,
    Space
} from "antd";
import SelectCustomerProduct from "./SelectCustomerProduct";
import SelectNormalizationVersion from "./SelectNormalizationVersion";

function DeleteNormalization({
                                 token,
                                 userName,
                             }: {
    token: string;
    userName: string;
}) {
    const authHeader = {Authorization: `Bearer ${token}`};
    const headers = {
        ...authHeader,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const [customer, setCustomer] = useState<string>();
    const [product, setProduct] = useState<string>();
    const [version, setVersion] = useState<number | undefined>();

    const {
        run: runNormalization
    } = useRequest<number[], any>(async () => {
        const res = await fetch(
            `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/normalization/${version}/manual`,
            {
                headers,
                method: "POST",
                body: JSON.stringify({
                    "user": userName
                })
            }
        );
        return res.json();
    }, {
        manual: true, onSuccess: () => {
            notification.success({message: "Normalization started!"});
        },
        onError: (e) => {
            console.log(e);
            notification.error({message: `Error running normalization ${e.message}`})
        }
    })

    const {
        run: createNormalizationVersion
    } = useRequest<number[], any>(async () => {
        const res = await fetch(
            `${process.env.REACT_APP_TBOARD_API_HOST}/${customer}/${product}/normalization_version/create`,
            {
                headers,
                method: "POST",
                body: JSON.stringify({
                    "user": userName
                })
            }
        );
        return res.json();
    }, {
        manual: true, onSuccess: () => {
            notification.success({message: "Normalization version created!"});
        },
        onError: (e) => {
            console.log(e);
            notification.error({message: `Error in creating normalization version ${e.message}`})
        }
    })

    const isEnabled = !Boolean(customer) || !Boolean(product) || !Boolean(version)
    const createVersionEnabled = !Boolean(customer) || !Boolean(product)
    return (
        <Space direction={"vertical"}>
            <div>
                <h3>Choose Product:</h3>
                <SelectCustomerProduct onChange={v => {
                    if (!v) {
                        setProduct(undefined);
                        setCustomer(undefined);
                        return;
                    }
                    setProduct(v.product);
                    setCustomer(v.customer);
                }}/>
            </div>
            {customer && product &&
            <div>
                <h3>Choose Version:</h3>
                <SelectNormalizationVersion
                    customer={customer}
                    product={product}
                    onChange={setVersion}/>
            </div>
            }
            <div>
                <Popconfirm
                    okText={"COOL"}
                    title={
                        <div>
                            We are going to normalize all data we have. Cool?
                        </div>
                    }
                    onConfirm={async () => {
                        await runNormalization()
                    }}>
                    <Button disabled={isEnabled} type={"primary"}
                            size="large">Normalize Data</Button>
                </Popconfirm>
                <Popconfirm
                    okText={"COOL"}
                    title={
                        <div>
                            We are going to create a new normalization. Please update configuration file.
                        </div>
                    }
                    onConfirm={async () => {
                        await createNormalizationVersion()
                    }}>
                    <Button disabled={createVersionEnabled} type={"primary"} style={{margin: '0px 115px'}}
                            size="large">Create Normalization Version</Button>
                </Popconfirm>
            </div>
        </Space>
    );
}

export default DeleteNormalization;
